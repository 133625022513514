export function getVideo(src) {
  const video = document.createElement("video")
  video.crossOrigin = "anonymous"
  video.src = src
  video.muted = true

  return video
}

export async function getTrackReader(video) {
  await video.play()
  const userAgent = navigator.userAgent
  let stream = null

  try {
    if (userAgent.indexOf('Firefox') > -1) {
      stream = video.mozCaptureStream()
    } else {
      stream = video.captureStream()
    }
  } catch (e) {
    return
  }

  const [track] = stream.getVideoTracks()
  await video.pause()

  if (!track) {
    return
  }

  try {
    const processor = new MediaStreamTrackProcessor(track)
    return processor.readable.getReader()
  } catch (e) {
    return
  }
}
